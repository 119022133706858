import React from 'react';
import useSWR, { BareFetcher } from "swr";

export interface APIResponse {
    user: string;
    message: string;
  }
  
  const fetcher: BareFetcher<APIResponse> = (url: string) =>
    fetch(url).then((res) => res.json());

export default function Home() {
    const { data, error } = useSWR<APIResponse, Error>("/api/hello", fetcher);

    if (data == undefined) {
      return (
        <div>Loading...</div>
      )
    }
  
    if (error) {
      return (
        <div>Opps! An unexpected error occurred. Please try again later.</div>
      );
    }

    return(
        <>
            <h1>Home</h1>
            <h2>Hello {data.user}!</h2>
            <h3>{data.message}</h3>
        </>
    );
}